<template>
  <div id="aboutUs">
    <!--头部导航-->
    <div style="z-index:9999;position:fixed;top:0;left: 0;">
      <baseNav></baseNav>
    </div>
    <!--内容-->
    <div class="aboutUsCon">
      <!--内容头部-->
      <div class="commenHead">
        <div class="about_tit">
          <p v-if="aboutUsData.title&&aboutUsData.title.length>0&&aboutUsData.title[0].title" class="animated bounceInLeft about_tit_one" v-html="aboutUsData.title[0].title"
          style="font-size: 5.278vw;font-family: Montserrat-Light,Montserrat;line-height: 6.805vw;width: 79.081vw;"
          ></p>
          <p v-if="aboutUsData.title&&aboutUsData.title.length>0&&aboutUsData.title[0].content" class="animated bounceInLeft about_tit_two" v-html="aboutUsData.title[0].content" 
          style="font-size:3.333vw; font-family:Montserrat;font-weight: 300;line-height: 4.444vw;color: #fff;"></p>
        </div>
        <div class="about_img">
          <div class="right_grid ">
            <img src="../assets/images/pic_grid_r.png" alt="">
          </div>
          <div class="left_grid " style="height: auto;">
            <img src="../assets/images/pic_grid_l.png" alt="">
          </div>
        </div>
      </div>



      <!--公司介绍-->
      <div class="gs_introduce">
        <div class="topLine"></div>
        <p class="tit_text" v-if="aboutUsData.introduct&&aboutUsData.introduct.length>0" v-html="aboutUsData.introduct[0].title"></p>
        <div class="intro_cont" v-if="aboutUsData.introduct&&aboutUsData.introduct.length>0">
          <p v-for="(item,index) in aboutUsData.introduct" :key="index">{{item.content}}</p>
        </div>
        <!--slogan 3-->
        <div class="gs_slogan">
          <div class="gs_slogan_top" v-if="aboutUsData.slogan&&aboutUsData.slogan.length>0">
            <div class="gs_slogan_item" >
              <p class="slogan_top" v-html="aboutUsData.slogan[0].content"></p>
              <p class="slogan_bottom" v-html="aboutUsData.slogan[0].title" ></p>
            </div>
            <div class="gs_slogan_item">
              <p class="slogan_top" v-html="aboutUsData.slogan[1].content"></p>
              <p class="slogan_bottom" v-html="aboutUsData.slogan[1].title" style="font-size:4.2vw"></p>
            </div>
          </div>
          <div class="gs_slogan_bottom" v-if="aboutUsData.slogan&&aboutUsData.slogan.length>2">
            <div class="gs_slogan_item">
              <p class="slogan_top"  v-html="aboutUsData.slogan[2].content"></p>
              <p class="slogan_bottom" v-html="aboutUsData.slogan[2].title"></p>
            </div>
          </div>
        </div>


<!--        模块介绍6-->
        <div class="introselect">
          <div class="sel_light"></div>
          <div class="introselitem" v-if="aboutUsData.model&&aboutUsData.model.length>0">
            <div class="selitemleft selitem" v-for="(item,index) in aboutUsData.model" :key="index">
              <img v-lazy=item.pic_image>
              <p>{{item.title}}</p>
              <div>
                <p v-html="item.content"></p>
              </div>
            </div>

<!--            写死的-->
            <div class="selitemleft selitem staticSelitem" style="width: 40%;">
              <!-- <img src='http://www.vivolight.com/static/en/images/ico_zhuanli.png'> -->
              <img src='../assets/images/ico_zhuanli@2x.png'>
              <p style="line-height: 3.333vw">62 <span> Approved patents</span></p >
              <p style="line-height: 3.333vw;margin-top: 0;">54 <span> Patents in process</span></p >
            </div>

            <div class="selitemleft selitem staticSelitem" style="width: 40%">
              <!-- <img src='http://www.vivolight.com//static/en/images/ico_10years.png' > -->
              <img src='../assets/images/ico_10years@2x.png' >
              <p>10 Years</p>
              <div>
                <p>Technique Heritage</p>
              </div>
            </div>

            <div class="selitemleft selitem staticSelitem" style="width: 20%;">
              <!-- <img src='http://www.vivolight.com/static/en/images/ico_team.png'> -->
              <img src='../assets/images/ico_team@2x.png'>
              <p>~40%</p>
              <div>
                <p>R&D team</p>
              </div>
            </div>
          </div>



<!--轮播图片-->
        </div>
        <div class="commenLunbo intrduceImg">
          <div class="swiper-container mySwiper" v-show="aboutUsLunboData&&aboutUsLunboData.length>0">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="item in aboutUsLunboData" :key="item.id"><img v-lazy="item.pic_image" alt=""></div>
            </div>
            <!-- 如果需要分页器 -->
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>


      <!--发展历程-->
      <div class="fzlc">
        <div class="topLine"></div>
        <p class="tit_text">Milestones of Vivolight</p>
      </div>

      <!--发展历程时间线-->
      <div class="timeLines timeLineLc clearfix">
        <div class="timeLineCon clearfix" style="height: 36vw;position: relative">
          <div class="swiper-container mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in yearListLc" :key="index" style="border-radius: 2.2222vw;">
                <div class="timeItems">
                  <h3>{{ item.year }}</h3>
                  <p v-if="item.content&&item.content.title" 
                  style="display:inline-block; width: 36vw; margin-top: 9vw;font-size: 3.333vw; font-family: Montserrat; line-height:4.722vw ;font-weight: 300;color:#fff" v-html="item.content.title"></p>
                </div>
              </div>
            </div>
          </div>
          <div style="position:absolute;top:15.277vw;border-bottom: 0.2777vw solid #20BFD0;width: 100vw;margin-top:-4.166vw;float:left;"></div>
        </div>
      </div>

      <!--发展历程的年份内容-->
      <div class="fzlcBox">
        <!--历程轮播-->
<!--        <div class="commenLunbo fzlcLunbo clearfix" v-show="fzlcList && fzlcList.length>0">-->
<!--          <img :src="theImage" alt="">-->
<!--        </div>-->

        <!--历程推荐-->
<!--        <ul class="fzlc_recommend" v-show="fzlcList && fzlcList.length>0">-->
<!--          <li v-for="(item,index) in fzlcList" :key="index" @click="recommendPic(index)" style="background: none">-->
<!--            <a href="javascript:;" :class="{imgActive:imgIndex==index}">-->
<!--              <span v-html="item.title"></span>-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
      </div>

      <!--使命与价值观-->
      <div class="mission">
        <div class="topLine"></div>
        <p class="tit_text" >Mission & Vision</p>
        <div class="missbj"></div>
<!--        mission-->
        <div class="intro_cont" v-if="aboutUsData.mission&&aboutUsData.mission[0]&&aboutUsData.mission.length>0">
          <p class="intro_cont_t" v-html="aboutUsData.mission[0].title"></p>
          <p class="intro_cont_con" v-html="aboutUsData.mission[0].content"></p>
        </div>
<!--vision-->
        <div class="intro_cont" v-if="aboutUsData.values&&aboutUsData.values[0]&&aboutUsData.values.length>0">
          <p class="intro_cont_t" v-html="aboutUsData.values[0].title"></p>
          <p class="intro_cont_con" v-html="aboutUsData.values[0].content"></p>
        </div>

      </div>

      <!--荣誉资质-->
      <div class="ryzz">
        <div class="topLine"></div>
        <p class="tit_text">Honors and Awards </p>
        <div class="back_plus"></div>
      </div>

      <!--荣誉资质时间线-->
      <div class="timeLines timeLineRy">
        <div class="timeLineCon">
          <div class="swiper-container mySwiper">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item,index) in yearListRy" :key="index">
                <div class="timeItems" :class="{timeActive:currentYearRy==item}" @click="changeRongyu(item)">
                  <h3>{{ item }}</h3>
                </div>
              </div>
            </div>
          </div>
          <div style="border-bottom: 0.2777vw solid #20BFD0;width: 100vw;margin-top:-4.166vw;float:left;"></div>
        </div>
      </div>
      <div class="ryzzBox">

        <!--荣誉推荐-->
        <ul class="ryzz_recommend" v-show="ryzzList && ryzzList.length>0">
          <li v-for="item in ryzzList" :key="item.id" style="padding-left: 5.555vw;">
            <a href="javascript:;" >
<!--              <img src="../assets/images/ico_arrow_pixel.png" alt="">-->
             <span v-html="item.title "></span>
            </a>
          </li>
        </ul>
      </div>




      <!--      视频宣传-->
      <div class="videoBox" >
        <div class="topLine"></div>
        <p class="tit_text">Media Report</p>
        <ul class="videoCon" v-show="videoList && videoList.length>0">
          <li v-for="item in videoList" :key="item.id" @click="toVideo(item['is_show_video'],item.video_file)">
            <a href="javascript:;" class="">
              <img v-lazy="item.thumb_image" alt="" >
              <p>{{ item.title }}</p>
              <div class="icon_video" v-show="item['is_show_video']!=0"></div>
            </a>
          </li>
        </ul>
      </div>
    </div>



    <!--    弹出层-->
    <van-overlay :show="isMaskShow" @click="closeVideo">
      <div class="videowrapper">
        <video-player class="video-player vjs-custom-skin"
                      ref="videoPlayer"
                      :playsinline="true"
                      :options="playerOptions">
        </video-player>
      </div>
    </van-overlay>



    <!-- footer-->
    <div class="footers">
      <Copyright></Copyright>
    </div>


  </div>
</template>

<script>

</script>
<script>

import baseNav from "./baseNav";
import Copyright from "@/component/Copyright";
import {GetYear,GetLunbo,GetAboutLicheng, GetAboutRongyu, GetAboutVideo,GetIndexDoc} from "@/api/api";
import Swiper from 'swiper';

export default {
  name: "aboutUs",
  data() {
    return {
      // 获取关于我们数据
      aboutUsData: {},
      // 获取关于我们轮播数据
      aboutUsLunboData: [],
      //发展历程年份
      yearListLc: [2022,2021, 2020, 2019, 2018, 2017],
      //荣誉资质年份
      yearListRy: [2022,2021, 2020, 2019, 2018, 2017],
      //发展历程当前年份
      currentYear: 2022,
      //发展历程当前年份
      currentYearRy: 2021,
      //当前年份发展历程List
      fzlcList: [],
      // 发展历程图片
      theImage:'',
      // 发展历程激活索引
      imgIndex:0,
      //当前年份荣誉资质List
      ryzzList: [],
      //视频页数
      currentPage: 1,
      //视频列表
      videoList: [],


      //弹层显示
      isMaskShow: false,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: true, // 如果为true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "video/mp4", // 类型
          src: ""  // url地址
        }],
        poster: '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true // 是否显示全屏按钮
        }
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0)
    //获取年份数据
    this.toGetYear()
    //获取关于我们数据
    this.toGetIndexDoc(2)
    //轮播图片
    this.toGetLunbo()
    //获取视频信息
    // this.toGetAboutVideo()
    //初始化分页器
    //公司介绍
    new Swiper('.gs_introduce .intrduceImg .mySwiper', {
      loop: true,
      // 如果需要分页器
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
      pagination: '.swiper-pagination',
      // 如果需要前进后退按钮
      // prevButton:'.swiper-button-prev',
      // nextButton:'.swiper-button-next',

      //如果需要自动切换海报
      autoplay: 3000,
      autoplayDisableOnInteraction: false,
    })
    //发展历程目录
    new Swiper('.timeLineLc .timeLineCon .mySwiper', {
      slidesPerView: 3,
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
    })
    //发展历程目录
    new Swiper('.timeLineRy .timeLineCon .mySwiper', {
      slidesPerView: 5,
      observer: true,//修改swiper自己或子元素时，自动初始化swiper
      observeParents: true,//修改swiper的父元素时，自动初始化swiper
    })
  },
  methods: {
    //获取年份
    toGetYear() {
      let _this = this
      let now = Date.now()
      let dataYear = JSON.parse(window.localStorage.getItem('dataYear_e'))
      console.log(dataYear)
      let expireds_year = window.localStorage.getItem('expireds_year_e')
      if (dataYear == null || now >= expireds_year) {
        window.localStorage.removeItem('dataYear_e');
        window.localStorage.removeItem('expireds_year_e');
        GetYear()
            .then(r => {
              let res = r.data
              if (res.status != 1) {
                _this.yearListLc = []
                _this.yearListRy = []
                return
              } else {
                console.log(res)
                let apiIndex = res.data
                _this.yearListLc = apiIndex.licheng
                _this.yearListRy = apiIndex.rongyu
                //发展历程当前年份
                _this.currentYear = apiIndex.licheng[0].year
                //荣誉当前年份
                _this.currentYearRy = apiIndex.rongyu[0]
                let apiyear = {'yearListLc':apiIndex.licheng,'yearListRy':apiIndex.rongyu}
                window.localStorage.setItem('dataYear_e', JSON.stringify(apiyear))
                window.localStorage.setItem('expireds_year_e', now + 1000 * 60 * 60 * 24)
                console.log('发起about year请求')
              }
            })
      } else {
        console.log('未发起about year请求')
        _this.yearListLc = dataYear.yearListLc
        _this.yearListRy = dataYear.yearListRy
        //发展历程当前年份
        _this.currentYear = dataYear.yearListLc[0].year
        console.log( _this.currentYear)
        //荣誉当前年份
        _this.currentYearRy = dataYear.yearListRy[0]
      }
      //发展历程信息
      _this.toGetAboutLicheng()
      //荣誉资质
      _this.toGetAboutRongyu()
      //媒体报道
      _this.toGetAboutVideo()
    },
    //获取首页数据
    toGetIndexDoc(data) {
      let _this = this
      let now = Date.now()
      let dataIndex = JSON.parse(window.localStorage.getItem('dataIndex_e'))
      let expireds_index = window.localStorage.getItem('expireds_index_e')
      console.log(dataIndex)
      console.log(now)
      if (dataIndex == null || now >= expireds_index) {
        window.localStorage.removeItem('dataIndex_e');
        window.localStorage.removeItem('expireds_index_e');
        GetIndexDoc(data)
            .then(r => {
              let res = r.data
              if (res.status != 1) {
                _this.aboutUsData = []
                return
              } else {
                let apiIndex = res.data
                _this.aboutUsData = apiIndex
                window.localStorage.setItem('dataIndex_e', JSON.stringify(apiIndex))
                window.localStorage.setItem('expireds_index_e', now + 1000 * 60 * 60 * 24)
                console.log('发起about index请求')
              }
              console.log(res.data);
            })
      } else {
        console.log('未发起about index请求')
        _this.aboutUsData = dataIndex
        console.log(dataIndex)
      }
    },
    // 获取轮播
    toGetLunbo() {
      let _this = this
      let now = Date.now()
      let dataLunbo = JSON.parse(window.localStorage.getItem('dataLunbo_e'))
      let expireds_lunbo = window.localStorage.getItem('expireds_lunbo_e')
      console.log(dataLunbo)
      console.log(now)
      if (dataLunbo == null || now >= expireds_lunbo) {
        window.localStorage.removeItem('dataLunbo_e');
        window.localStorage.removeItem('expireds_lunbo_e');
        GetLunbo()
            .then(r => {
              let res = r.data
              if (res.status != 1) {
                _this.aboutUsLunboData = []
                return
              } else {
                let apiIndex = res.data
                _this.aboutUsLunboData = apiIndex
                window.localStorage.setItem('dataLunbo_e', JSON.stringify(apiIndex))
                window.localStorage.setItem('expireds_lunbo_e', now + 1000 * 60 * 60 * 24)
                console.log('发起about lunbo请求')
              }
            })
      } else {
        console.log('未发起about lunbo请求')
        _this.aboutUsLunboData = dataLunbo
      }
    },
    //获取公司历程信息
    toGetAboutLicheng() {
      let _this = this
      GetAboutLicheng(_this.currentYear)
          .then(r => {
            let res = r.data
            if (res.status != 1) {
              // _this.$message.error('获取信息失败')
              _this.fzlcList = []
              return
            }else{
              // 获取列表成功
              let apifzlcList = res.data
              _this.fzlcList = apifzlcList
              _this.theImage = apifzlcList[0].thumb_image
            }
          })
    },
    //获取荣誉数据信息
    toGetAboutRongyu() {
      let _this = this
      GetAboutRongyu(_this.currentYearRy)
          .then(r => {
            let res = r.data
            if (res.status != 1) {
              // _this.$message.error('获取信息失败')
              _this.ryzzList = []
              return
            }else{
              // 获取列表成功
              let apiryzzList = res.data
              _this.ryzzList = apiryzzList
            }
          })
    },

    //获取视频数据信息
    toGetAboutVideo() {
      let _this = this
      let now=Date.now()
      let dataVideo =JSON.parse(window.localStorage.getItem('dataVideo_e'))
      let expireds_video =window.localStorage.getItem('expireds_video_e')
      console.log(dataVideo)
      console.log(now)
      if (dataVideo == null || now >= expireds_video) {
        window.localStorage.removeItem('dataVideo_e');
        window.localStorage.removeItem('expireds_video_e');
        GetAboutVideo(_this.currentPage)
            .then(r => {
              let res = r.data
              if (res.status != 1) {
                // _this.$message.error('获取信息失败')
                _this.videoList = []
                return
              }else{
                // 获取列表成功
                let apivideoList = res.data[0].list
                _this.videoList = apivideoList
                window.localStorage.setItem('dataVideo_e',JSON.stringify(apivideoList))
                window.localStorage.setItem('expireds_video_e',now+1000*60*60*24)
                console.log('发起about Video请求')
              }
            })
      }else{
        console.log('未发起about video请求')
        console.log( dataVideo)
        _this.videoList = dataVideo
      }

    },
    //点击发展历程年份
    changeLicheng(data) {
      this.currentYear = data
      this.toGetAboutLicheng(this.currentYear)
      this.imgIndex=0

    },
    recommendPic(data){
      this.imgIndex=data
      this.theImage=this.fzlcList[data].thumb_image
    },
    //点击荣誉资质年份
    changeRongyu(data) {
      this.currentYearRy = data
      this.toGetAboutRongyu(this.currentYearRy)
      this.imgIndex=0
    },
    //点击播放视频
    toVideo(show,data) {
      console.log(show+'------'+data)
      if(show==0){
        return
      }else{
        this.playerOptions.sources[0].src = data
        this.isMaskShow = true
      }

    },
    closeVideo(){
      this.isMaskShow = false
      let _this=this
      setTimeout(function (){
        _this.playerOptions.sources[0].src = ''
      },1000)
    }

  },
  components: {
    baseNav,
    Copyright
  }
}
</script>

<style lang="less" scoped>
.videowrapper {
  margin-top: 50vh;
  transform: translateY(-50%);
}

#aboutUs {
  background-color: #071D42;
}

.aboutUsCon {
  padding-top: 13.888vw;
  background-color: #071D42;
  width: 100%;
  .commenHead {
    background: url("../assets/images/pic_banner_about.png") no-repeat center top;
    background-size: 100%;
  }

  //公司介绍
  .gs_introduce {
    margin: 0 6.666vw;

    .gs_slogan{
      margin-top: 7.7781vw;
      .gs_slogan_top{
        display: flex;
        .gs_slogan_item{
          flex: 1;
          border-left:0.5556vw solid #20BFD0;
          padding:0 2.222vw;
          box-sizing: border-box;

        }
      }
      .gs_slogan_bottom{
        margin-top: 6.944vw;
        border-left:0.5556vw solid #20BFD0;
        padding:0 2.222vw;
        box-sizing: border-box;
      }
      .slogan_top{
        font-size: 2.777vw;
        font-family: Montserrat-ExtraLight, Montserrat;
        font-weight: 200;
        color: #FFFFFF;
        line-height: 3.0555vw;
        margin-bottom: 1.944vw;
      }
      .slogan_bottom{
        font-size: 4.444vw;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #20BFD0;
        line-height: 4.722vw;
      }

    }
    .introselect {
      position: relative;
      padding-top: 5vw;

      .sel_light {
        width: 60vw;
        height: 131.388vw;
        background: url(../assets/images/pic_blue_light.png) no-repeat left;
        background-size: cover;
        position: absolute;
        top: -28%;
        left: -9%;
      }

      .introselitem {
        position: relative;

        > div {
          display: inline-block;
          margin-top:8.333vw;

          img {
            width: 11.111vw;
            height: 11.111vw;
            display: block;
            margin: 0 auto;
          }

          > p {
            text-align: center;
            font-size: 3.333vw;
            font-weight: 400;
            color: #20BFD0;
            line-height: 4.0277vw;
            margin: 3.333vw 0 1.1111vw 0;
            font-family: Montserrat-Regular, Montserrat;
            span{
              font-size: 2.777vw;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 3.333vw;
              margin-left: 1.111vw;
            }
          }

          > div {
            margin-top: 1.5277vw;
            p {
              text-align: center;
              font-size: 2.777vw;
              font-weight: 400;
              color: #fff;
              line-height: 3.0555vw;
              font-family: Montserrat-Regular, Montserrat;
            }
          }
        }
        .selitem:nth-child(2n-1){
          padding-right: 2px
        }
        .selitem:nth-child(2n){
          padding-left: 2px;
        }
        .selitem{
          width: calc(43.334vw - 4px);
          box-sizing: border-box;
        }
        .staticSelitem{
          //width: calc(29.556vw - 4px);
          box-sizing: border-box;
        }
      }
      .introselitem .selitem:nth-child(2){
        transform: translateY(-2.6vw);
      }

    }

    .intrduceImg {
      margin-top: 11.111vw!important;
      .swiper-slide{
        width: 100%!important;
        height: 54.1675vw;
        img{
          width: 100%;
        }
      }
    }
  }

  //使命与价值
  .mission {
    position: relative;
    padding: 20vw 6.666vw 0;

    .missbj {
      width: 62.2222vw;
      height: 138.8888vw;
      background: url("../assets/images/missbj.png") no-repeat left;
      background-size: cover;
      position: absolute;
      top: -43.6666vw;
      right: 0;
    }
    .intro_cont{
      margin-top: 7.222vw;
      .intro_cont_t{
        font-size: 4.444vw;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 5.4166vw;
      }
      .intro_cont_con{
        font-size: 3.333vw;
        font-weight: 300;
        font-family:  Montserrat;
        color: #FFFFFF;
        line-height: 4.722vw;
      }
    }
  }

  //发展历程
  .fzlc {
    position: relative;
    margin: 20vw 6.666vw 0;
  }

  //发展历程时间线内容盒子
  .fzlcBox, .ryzzBox {
    margin: 0 6.666vw;

    .fzlcLunbo{
      margin-top: 4.722vw!important;
    }

    .fzlcLunboHide {
      display: none;
    }

    .fzlc_recommend, .ryzz_recommend {
      margin-top: 4.722vw;

      li {
        height: auto;
        color: #fff;
        margin: 3vw 0;
        background: url("../assets/images/ico_arrow_pixel.png") no-repeat left 1vw;
        a {
          font-size: 3.333vw;
          line-height: 4.722vw;
          font-family: Montserrat;
          font-weight: 300;
          color: #fff;
          // line-height: 8.0555vw;
        }
        .imgActive{
          color:#20BFD0;
        }
      }
    }

 
  }

  //荣誉资质
  .ryzz {
    position: relative;
    padding: 20vw 6.666vw 0;

    .back_plus {
      position: absolute;
      right: 0;
      top: 2vw;
      width: 70.625vw;
      height: 33.125vw;
      background: url("../assets/images/pic_plus.png") no-repeat right;
      background-size: cover;
    }


  }

  //视频推荐
  .videoBox {
    margin: 8.333vw 6.666vw;
    margin-top: 20vw;
    .videoCon {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      li {
        position: relative;
        width: 42vw;
        box-sizing: border-box;

        a {
          display: block;

          img {
            width: 42vw;
            height: 26.38vw;
            border-radius: 1.1111vw;
          }

          p {
            margin-top: 2.2222vw;
            font-size: 3.333vw;
            font-weight: 300;
            color: #fff;
            line-height: 4.722vw;
            margin-bottom: 5.555vw;
            text-align: left;
            font-family: Montserrat;
          }

          .icon_video {
            display: inline-block;
            width: 8.333vw;
            height: 8.333vw;
            background: url("../assets/images/icon_video.png") no-repeat center;
            background-size: cover;
            position: absolute;
            top: 13.19vw;
            left: 21vw;
            transform: translate(-50%,-50%);
          }
        }


      }

    }

  }

  .topLine {
    width: 11.111vw;
    height: 3px;
    margin-bottom: 13px;
    background-color: #20BFD0;
  }

  .tit_text {
    font-size: 5.555vw;
    font-weight: 300;
    color: #20BFD0;
    line-height: 6.805vw;
    font-family: Montserrat-Light, Montserrat;
  }

  .intro_cont {

    p {
      font-size: 3.333vw;
      font-weight: 300;
      color: #bbb;
      line-height: 4.722vw;
      margin-bottom: 3.333vw;
      text-align: left;
      position: relative;
      font-family: Montserrat-Light, Montserrat;
    }
  }
}


.footers {
  width: 100%;
  height: 43.6666vw;
  padding-top:15.5555vw;
  background: url("../assets/images/foobj.png") no-repeat right bottom;
  background-size: 101%;
  box-sizing: border-box;
}


</style>
<style>
.intrduceImg .swiper-pagination-bullet {
  width: 1.1111vw !important;
  height: 1.1111vw !important;
  margin: 0 0.5555vw !important;
}

.intrduceImg .swiper-pagination-bullet-active {
  width: 6.3888vw !important;
  height: 1.1111vw;
  border-radius: 0.5555vw !important;
  border: 1px solid #ffffff;
  background: transparent !important;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  opacity: 1;

}

.intrduceImg .swiper-pagination-bullet-active::after {
  content: '';
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 1.1111vw;
  background: #fff;
  animation: intrduceImgLunbo 3s linear !important;
}

@keyframes intrduceImgLunbo {
  from {
    width: 0px;
  }
  to {
    width: 100%;
  }

}
</style>
